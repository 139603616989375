import data from "./data";
import logo from "./assets/images/logo.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
import { RouterProvider } from "react-router-dom";
import router from "./router";

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
