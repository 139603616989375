import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Menu from "./pages/Menu";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Menu />,
  },
  {
    path: "menu",
    element: <Menu />,
  },
]);

export default router