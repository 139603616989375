import data from "./../data";
import logo from "./../assets/images/logo.svg"
import { Swiper, SwiperSlide } from 'swiper/react';

function Menu() {
  return (
    <div className="stock bg-black min-h-screen w-full bg-[url('./assets/images/background.jpg')] bg-center bg-cover">
      <div className="stock-wrapper p-2">
        <div className="text-[#db004c] text-[150px] font-['Space_Grotesk'] leading-[115px] h-[45px] hidden sm:block">"</div>
        <img className="logo block sm:hidden ml-auto mr-auto mb-3" src={logo} alt="img" />
        <div className="grid grid-cols-1 md:grid-cols-2 border-white rounded-3xl border-2 p-2 text-white">
          <div className="presentation hidden md:block p-5">
            <img className="logo" src={logo} alt="img" />
            <div className="flex items-center justify-center flex-col mt-32">
              <div className="text-4xl uppercase font-bold">Bon appétit !</div>
              <div className="text-2xl text-center">Explorez nos offres exceptionnelles pendant notre promotion d'ouverture.</div>
            </div>
          </div>
          <div className="menu bg-black p-5 bg-opacity-65 rounded-2xl">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              autoHeight={false}
              className="h-full"
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)} >
              {
                data.map(menu => (
                  <SwiperSlide className="h-full">
                    <div className="menu-item">
                      <div className="item-header mb-5">
                        <div className="header-title text-[#009fe0] text-4xl">{menu.title}</div>
                      </div>
                      <div className="item-content">
                        <div className="content-wrapper flex flex-col gap-10">
                          {menu.items.map(item => (
                            <div className="flex justify-between">
                              <div>{item.name}</div>
                              <div className="text-[#db004c]">{item.price} DT</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Menu;
