const data = [
  {
    title: 'Petit Déjeuner',
    items: [
      {
        name: 'Cake Protéiné',
        description:null,
        price: '3,000'
      },
      {
        name: 'Pain Cakes Fruit rouge',
        description:null,
        price: '10,000'
      },
      {
        name: 'Pain Cakes Banane Fruit secs ',
        description:null,
        price: '10,000'
      },
      {
        name: 'Brunch',
        description:null,
        price: '13,000'
      },
    ]
  },
  {
    title: 'Café',
    items: [
      {
        name: 'Express',
        description:null,
        price: '2,500'
      },
      {
        name: 'Américain',
        description:null,
        price: '2,500'
      },
      {
        name: 'Capucin',
        description:null,
        price: '3,000'
      },
      {
        name: 'Café crème',
        description:null,
        price: '3,000'
      },
    ]
  },
  {
    title: 'Café Glacé',
    items: [
      {
        name: 'Café Glacé Vanille',
        description:null,
        price: '8,000'
      },
      {
        name: 'Café Glacé Gorome Thé',
        description:null,
        price: '8,000'
      },
      {
        name: 'Café Glacé Noisette',
        description:null,
        price: '8,000'
      },
      {
        name: 'Café Glacé Chocolat Cookies',
        description:null,
        price: '8,000'
      },
      {
        name: 'Café Glacé Black Berry ',
        description:null,
        price: '8,000'
      },
    ]
  },
  {
    title: 'Café Aromé',
    items: [
      {
        name: 'Café Crème Vanille',
        description:null,
        price: '8,500'
      },
      {
        name: 'Café Crème Caramel ',
        description:null,
        price: '8,500'
      },
      {
        name: 'Café Crème Noisette',
        description:null,
        price: '8,500'
      },
      {
        name: 'Café Crème Speculoos',
        description:null,
        price: '8,500'
      },
      {
        name: 'Café Crème Chocolat Blanc',
        description:null,
        price: '8,500'
      },
      {
        name: 'Café Crème PopCorn',
        description:null,
        price: '8,500'
      },
    ]
  },
  {
    title: 'Jus',
    items: [
      {
        name: 'Citronnade',
        description:null,
        price: '4,000'
      },
      {
        name: 'Citronnade Sans Sucre',
        description:null,
        price: '4,000'
      },
      {
        name: 'Jus Fraise',
        description:null,
        price: '5,000'
      },
      {
        name: 'Jus Fraise Sans Sucre',
        description:null,
        price: '5,000'
      },
      {
        name: 'Citronnade Menthe Sans Sucre',
        description:null,
        price: '5,000'
      },
      {
        name: 'Citronnade Menthe',
        description:null,
        price: '5,000'
      },
      {
        name: 'Jus Banane',
        description:null,
        price: '6,000'
      },
      {
        name: 'Jus Kiwi',
        description:null,
        price: '7,000'
      },
      {
        name: 'Jus Ananas',
        description:null,
        price: '7,000'
      },

    ]
  },
  {
    title: 'Cocktail Duo',
    items: [
      {
        name: 'Cocktail Duo Marocana',
        description:null,
        price: '5,000'
      },
      {
        name: 'Cocktail Duo Tropicana',
        description:null,
        price: '8,000'
      },
      {
        name: 'Cocktail Duo Passion',
        description:null,
        price: '10,000'
      },
      {
        name: 'Cocktail Duo Pulse',
        description:null,
        price: '10,000'
      },
      {
        name: 'Cocktail Duo Exotic',
        description:null,
        price: '15,000'
      },
    ]
  },
  {
    title: 'Cocktail Trio',
    items: [
      {
        name: 'Cocktail Trio Mint',
        description:null,
        price: '9,000'
      },
      {
        name: 'Cocktail Trio Sunset',
        description:null,
        price: '9,000'
      },
      {
        name: 'Cocktail Trio Fresh',
        description:null,
        price: '10,000'
      },
      {
        name: 'Cocktail Trio Angel',
        description:null,
        price: '11,000'
      },
      {
        name: 'Cocktail Trio Lisbonne',
        description:null,
        price: '15,000'
      },
      {
        name: 'Cocktail Trio Power',
        description:null,
        price: '16,000'
      },
      {
        name: 'Cocktail Trio Florida',
        description:null,
        price: '16,000'
      },
      {
        name: 'Cocktail Trio Banana Moon',
        description:null,
        price: '18,000'
      },
      {
        name: 'Cocktail Trio Pinky',
        description:null,
        price: '18,000'
      },
      {
        name: 'Cocktail Trio Paradise',
        description:null,
        price: '18,000'
      }

    ]
  },
  {
    title: 'Cocktail Carb',
    items: [
      {
        name: 'Cocktail Pomme Proteine',
        description:null,
        price: '5,000'
      },
      {
        name: 'Cocktail Peche Proteine',
        description:null,
        price: '6,000'
      },
      {
        name: 'Cocktail Fruit Rouge Proteine',
        description:null,
        price: '6,000'
      },
      {
        name: 'Cocktail Ananas Proteine',
        description:null,
        price: '7,000'
      },
      {
        name: 'Cocktail Banane Proteine',
        description:null,
        price: '8,000'
      },
    ]
  },
  {
    title: 'Cocktail Whey',
    items: [
      {
        name: 'Cocktail Peche Proteine',
        description:null,
        price: '6,000'
      },
      {
        name: 'Cocktail Fruit Rouge Proteine',
        description:null,
        price: '6,000'
      },
      {
        name: 'Cocktail Fruit Rouge Proteine',
        description:null,
        price: '6,000'
      },
      {
        name: 'Cocktail Ananas Proteine Prostar Whey',
        description:null,
        price: '7,000'
      },
      {
        name: 'Cocktail Banane Proteine',
        description:null,
        price: '8,000'
      },
    ]
  },
  {
    title: 'Cocktail Iso',
    items: [
      {
        name: 'Cocktail Peche Proteine',
        description:null,
        price: '6,000'
      },
      {
        name: 'Cocktail Fruit Rouge Proteine',
        description:null,
        price: '6,000'
      },
      {
        name: 'Cocktail Fruit Rouge Proteine',
        description:null,
        price: '6,000'
      },
      {
        name: 'Cocktail Ananas Proteine',
        description:null,
        price: '7,000'
      },
      {
        name: 'Cocktail Banane Proteine',
        description:null,
        price: '8,000'
      },
      {
        name: 'Cocktail Hydro',
        description:null,
        price: '12,000'
      },
    ]
  },
  {
    title: 'Protein dose',
    items: [
      {
        name: 'WHEY PROTEINE',
        description:null,
        price: '3,000'
      },
      {
        name: 'BCAA',
        description:null,
        price: '3,500'
      },
      {
        name: 'MASS GAINER',
        description:null,
        price: '3,500'
      },
      {
        name: 'WHEY ISO',
        description:null,
        price: '4,000'
      },
      {
        name: 'GAINER JUICE',
        description:null,
        price: '4,000'
      },
      {
        name: 'WHEY PROTEINE ANIMAL',
        description:null,
        price: '4,500'
      },
      {
        name: 'HYDRO WHEY',
        description:null,
        price: '6,500'
      },
    ]
  },
  {
    title: 'Mojito',
    items: [
      {
        name: 'Mojito Fruit Rouge',
        description:null,
        price: '9,000'
      },
      {
        name: 'Mojito Blue',
        description:null,
        price: '9,000'
      },
      {
        name: 'Mojito Framboise',
        description:null,
        price: '9,000'
      },
      {
        name: 'Mojito classique',
        description:null,
        price: '9,000'
      },
      {
        name: 'Mojito Tonic',
        description:null,
        price: '9,000'
      },
    ]
  },
  {
    title: 'Smoothies',
    items: [
      {
        name: 'Smoothie Punchy',
        description:null,
        price: '8,000'
      },
      {
        name: 'Smoothie Strawberry',
        description:null,
        price: '8,000'
      },
      {
        name: 'Smoothie Tropicoh',
        description:null,
        price: '8,000'
      },
      {
        name: 'Smoothie Tonic',
        description:null,
        price: '9,000'
      },
      {
        name: 'Smoothie Sweety',
        description:null,
        price: '9,000'
      },
    ]
  },
  {
    title: 'Salade',
    items: [
      {
        name: 'Salade Festival',
        description:null,
        price: '13,000'
      },
      {
        name: 'Salade César',
        description:null,
        price: '14,000'
      },
      {
        name: 'Salade Italienne',
        description:null,
        price: '16,500'
      },
      {
        name: 'Salade Fraicheur',
        description:null,
        price: '19,000'
      },
      {
        name: 'Salade Exotique',
        description:null,
        price: '25,000'
      },
      {
        name: 'Salade Thai',
        description:null,
        price: '27,000'
      },
    ]
  },
  {
    title: 'Plat',
    items: [
      {
        name: 'Plat Cuise de Poulet',
        description:'(300G) légumes, Riz',
        price: '18,000'
      },
      {
        name: 'Plat Supreme de Poulet',
        description:'(250G) légumes, Riz ',
        price: '19,500'
      },
      {
        name: 'Plat Filet de Loup',
        description:'(250G) légumes, purée',
        price: '26,000'
      },
      {
        name: 'Plat Faux Filet',
        description:'(200G) légumes, pomme au four',
        price: '28,000'
      },
      {
        name: 'Plat Filet Classic',
        description:'(200G) légumes, pomme au four',
        price: '48,000'
      },
      {
        name: 'Nutriton X',
        description:'Beuf 100G Poulet 100G Chevrette 70G',
        price: '48,000'
      },
      {
        name: 'Plat Saumon',
        description:'(150G) légumes, purré',
        price: '50,000'
      },
    ]
  },
  {
    title: 'Dessert',
    items: [
      {
        name: 'Salade Fruits',
        description:null,
        price: '8,000'
      }
    ]
  },
  {
    title: 'Boissons',
    items: [
      {
        name: 'Eau 1.5L',
        description:null,
        price: '2,000'
      },
      {
        name: 'Cocacola Zero',
        description:null,
        price: '3,000'
      },
    ]
  },
  {
    title: 'Divers',
    items: [
      {
        name: 'Divers',
        description:null,
        price: '1,000'
      },
    ]
  },

]

export default data